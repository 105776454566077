<template>
  <v-app class="transparent">
    <v-container fluid>
      <div class="text-center vertically-centered" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <div>
          <v-expansion-panels
            v-if="!loading"
            v-model="panel"
            :disabled="disabled"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="display-2">Question Body</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="display-1">
                  {{ consultation.serviceQuestion.question_body }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
                <v-expansion-panel-header class="display-2">Patient Info</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>
                                    <v-chip
                                            class="ma-2 title"
                                            color="default">
                                        {{ consultation.phr.name }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td>Gender:</td>
                                <td>
                                    <v-chip
                                            class="ma-2 title"
                                            color="default">
                                        {{ consultation.phr.gender }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td>Date of Birth:</td>
                                <td>
                                    <v-chip
                                            class="ma-2 title"
                                            color="default">
                                        {{ consultation.phr.date_of_birth }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone Number:</td>
                                <td>
                                    <v-chip
                                            class="ma-2 title"
                                            color="default">
                                        {{ consultation.phr.phone }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td>Insurance Number:</td>
                                <td>
                                    <v-chip
                                            class="ma-2 title"
                                            color="default">
                                        {{ consultation.phr.insurance_no }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td>Policy Number:</td>
                                <td>
                                    <v-chip
                                            class="ma-2 title"
                                            color="default">
                                        {{ consultation.phr.policy_no }}
                                    </v-chip>
                                </td>
                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
            <v-expansion-panel-header class="display-2">Recommendation Info</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>Diagnosis</td>
                    <td>
                      <template v-for="diagnosis in consultation.icd10Diagnosis">
                        <v-chip
                          class="ma-2 title"
                          color="default">
                          {{ diagnosis.icd_10_code ? diagnosis.icd_10_code : diagnosis.name_ar }}
                        </v-chip>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Fda Drugs</td>
                    <td>
                      <template v-for="fdaDrug in consultation.callbackRecommendationInfo.drug.fdaDrug">
                        <v-chip
                          class="ma-2 title"
                          color="default">
                          {{ fdaDrug.name }}
                        </v-chip>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Labs</td>
                    <td>
                      <template v-for="lab in consultation.labCodes">
                        <v-chip
                          class="ma-2 title"
                          color="default">
                          {{ lab.name_en }}
                        </v-chip>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Doctor Referrals</td>
                    <td>
                      <template v-for="speciality in consultation.specialties">
                        <v-chip
                            class="ma-2 title"
                            color="default">
                          {{ speciality.name_ar }}
                        </v-chip>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="consultation.prescriptionPdfUrl && consultation.callbackRecommendationInfo.drug.fdaDrug.length > 0">
                      <td>
                          <div class="ma-2">
                              <v-btn x-large color="success" dark @click="downloadPrescription(consultation.prescriptionPdfUrl)">
                                  Download Prescription
                              </v-btn>
                          </div>
                      </td>
                  </tr>
                  </tbody>
                </template>

              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="display-2">Form Submission</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Labs Form -->
                <v-form ref="labsForm" v-model="validForms.labsForm" class="form-section" v-if="twConsultationFiltering.has_labs">
                  <v-row>
                    <v-col cols="12">
                      <h3>Labs</h3>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="forms.labs.has_labs_comment"
                          label="Labs Comment"
                          placeholder="Add notes or comments"
                          rows="2"
                          outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="forms.labs.has_labs_status"
                          :items="statuses"
                          label="Labs Status"
                          outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                          type="submit"
                          color="primary"
                          @click="submitLabsForm"
                          :loading="submitLabsLoading"
                          :disabled="submitLabsLoading"
                      >
                        Submit Labs
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <!-- Medications Form -->
                <v-form ref="medicationsForm" v-model="validForms.medicationsForm" class="form-section" v-if="twConsultationFiltering.has_medications">
                  <v-row>
                    <v-col cols="12">
                      <h3>Medications</h3>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="forms.medications.has_medications_comment"
                          label="Medications Comment"
                          placeholder="Add notes or comments"
                          rows="2"
                          outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="forms.medications.has_medications_status"
                          :items="statuses"
                          label="Medications Status"
                          outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-btn type="submit" color="primary" @click="submitMedicationsForm"
                             :loading="submitMedicationLoading"
                             :disabled="submitMedicationLoading"
                      >Submit Medications</v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <!-- Referrals Form -->
                <v-form ref="referralsForm" v-model="validForms.referralsForm" class="form-section" v-if="twConsultationFiltering.has_referral">
                  <v-row>
                    <v-col cols="12">
                      <h3>Referrals</h3>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="forms.referrals.has_referral_comment"
                          label="Referrals Comment"
                          placeholder="Add notes or comments"
                          rows="2"
                          outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                          v-model="forms.referrals.has_referral_status"
                          :items="statuses"
                          label="Referrals Status"
                          outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-btn type="submit" color="primary" @click="submitReferralsForm"
                             :loading="submitReferralLoading"
                             :disabled="submitReferralLoading"
                      >Submit Referrals</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
    </v-container>
    <ErrorDialog ref="errorDialog" :message="errorMessage"></ErrorDialog>
  </v-app>
</template>

<script>
  import ErrorDialog from "@/components/base/ErrorDialog.vue";
  export default {
    name: "ShowReferral",
    components: {ErrorDialog},
    created() {
      this.getConsultation();
    },
    data() {
      return {
        loading: false,
        showFollowUp: false,
        consultation_id: this.$route.params.id,
        panel: [0, 1],
        disabled: false,
        readonly: false,
        consultation: {},
        statuses: ['In-Progress', 'Rejected', 'Done'],
        validForms: {
          labsForm: false,
          medicationsForm: false,
          referralsForm: false
        },
        errorMessage: '',
        twConsultationFiltering: {},
        forms: {
          labs: {},
          medications: {},
          referrals: {}
        },
        submitLabsLoading: false,
        submitMedicationLoading: false,
        submitReferralLoading: false
      }
    },
    methods: {
      downloadPrescription(url) {
          window.location.href = url
      },
      getConsultation () {
        this.loading = true;
        this.$authApi.get(`consultations/${this.consultation_id}`).then(res => {
          this.consultation = res.data.data;
          this.twConsultationFiltering = res.data.data.twConsultationFiltering;
          this.fillForms();
          console.log(this.consultation);
          this.loading = false;
        })
      },
      fillForms() {
        this.forms.labs = {
          type: 'labs',
          has_labs: this.twConsultationFiltering.has_labs,
          has_labs_status: this.twConsultationFiltering.has_labs_status,
          has_labs_comment: this.twConsultationFiltering.has_labs_comment,
        };
        this.forms.medications = {
          type: 'medications',
          has_medications: this.twConsultationFiltering.has_medications,
          has_medications_status: this.twConsultationFiltering.has_medications_status,
          has_medications_comment: this.twConsultationFiltering.has_medications_comment,
        };
        this.forms.referrals = {
          type: 'referral',
          has_referral: this.twConsultationFiltering.has_referral,
          has_referral_status: this.twConsultationFiltering.has_referral_status,
          has_referral_comment: this.twConsultationFiltering.has_referral_comment,
        };
        console.log(this.forms);
      },
      submitLabsForm() {
        this.submitLabsLoading = true;
        this.$authApi.post(`consultations/${this.consultation_id}/submit-filtering`, this.forms.labs).then(res => {
          this.submitLabsLoading = false;
          this.flashMessage.success({
            title: 'Lab Info Saved',
            message: `Lab Info Saved Successfully`,
          });
        }).catch(error => {
          if(error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          } else {
            this.errorMessage = 'Please complete the required actions before saving.';
          }
          this.submitLabsLoading = false;
        });
      },
      submitMedicationsForm() {
        this.submitMedicationLoading = true;
        this.$authApi.post(`consultations/${this.consultation_id}/submit-filtering`, this.forms.medications).then(res => {
          this.submitMedicationLoading = false;
          this.flashMessage.success({
            title: 'Medication Info Saved',
            message: `Medication Info Saved Successfully`,
          });
        }).catch(error => {
          if(error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          } else {
            this.errorMessage = 'Please complete the required actions before saving.';
          }
          this.submitMedicationLoading = false;
        });
      },
      submitReferralsForm() {
        this.submitReferralLoading = true;
        this.$authApi.post(`consultations/${this.consultation_id}/submit-filtering`, this.forms.referrals).then(res => {
          this.submitReferralLoading = false;
          this.flashMessage.success({
            title: 'Referral Info Saved',
            message: `Referral Info Saved Successfully`,
          });
        }).catch(error => {
          if(error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          } else {
            this.errorMessage = 'Please complete the required actions before saving.';
          }
          this.submitReferralLoading = false;
        });
      }
    }
  }
</script>


<style scoped>
  .vertically-centered {
    margin: 20% auto
  }
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .form-section {
    border: 1px solid #ddd; /* Light gray border */
    padding: 16px;           /* Padding inside the border */
    border-radius: 8px;      /* Rounded corners */
    margin-bottom: 20px;     /* Space between each form */
  }
</style>
